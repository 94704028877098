import React from 'react';
import SEO from '../components/SEO/SEOZopim';
import { wrapper } from '../utils/style';
import styled from '@emotion/styled';

const Wrapper = styled('div')`
  ${wrapper}
  margin: 30px auto;
  p {
    margin-bottom: 1rem;
  }
`;

const Zopim = () => (
  <div>
    <SEO title='Live chat' description='Live chat page' pathname='/zopim/'/>
  </div>
);

export default Zopim;
